export default defineNuxtRouteMiddleware((to, from) => {
    const config = useRuntimeConfig()
    const cookie = useCookie('auth')
    console.log("Cookie auth");
    console.log(cookie.value);

    //console.log(to);

    if(cookie.value === undefined)
    {
        if (to.path !== '/login') return navigateTo('/login');
    }
})